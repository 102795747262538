





























import XDisplay from "@common/modules/vuetifyx/components/XDisplay.vue";
import Vue from "vue";
export default Vue.extend({
  components: { XDisplay },
  props: ["item", "displayFields", "hiddenFields"],
  data() {
    return {
      changedData: {},
    };
  },
  created() {
    const targetBefore = this.item?.targetBefore || {};
    const targetAfter = this.item?.targetAfter || {};
    for (const targetBeforeKey in targetBefore) {
      if (JSON.stringify(targetBefore[targetBeforeKey]) !== JSON.stringify(targetAfter[targetBeforeKey])) {
        this.changedData[targetBeforeKey] = {
          before: targetBefore[targetBeforeKey],
          after: targetAfter[targetBeforeKey],
        };
      }
    }
    for (const targetAfterKey in targetAfter) {
      if (this.changedData[targetAfterKey]) {
        continue;
      }
      if (JSON.stringify(targetBefore[targetAfterKey]) !== JSON.stringify(targetAfter[targetAfterKey])) {
        this.changedData[targetAfterKey] = {
          before: targetBefore[targetAfterKey],
          after: targetAfter[targetAfterKey],
        };
      }
    }
    delete this.changedData._id;
    delete this.changedData.createdTime;
    delete this.changedData.createdByUserId;
    delete this.changedData.updatedTime;
    delete this.changedData.lastUpdatedByUserId;
    delete this.changedData.migratedVersion;
    for (const key in this.changedData) {
      if (
        key.startsWith("_") ||
        key.endsWith("Id") ||
        key.endsWith("Ids") ||
        (this.hiddenFields && this.hiddenFields.includes(key))
      ) {
        delete this.changedData[key];
      }
    }
  },
});
