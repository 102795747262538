










import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import { getDeepDifference } from "@gthrm/deep-diff";
import ChangedData from "./item-history/ChangedData.vue";

export default Vue.extend({
  components: { ChangedData },
  props: ["item", "targetType", "displayFields", "hiddenFields"],
  data() {
    return {
      tableOptions: null,
    };
  },
  async created() {
    this.tableOptions = this.makeTableOptions(this.item);
  },
  methods: {
    makeTableOptions(item) {
      const self = this;

      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: false,
          search: {
            ext: {
              hidden: true,
            },
          },
          topActionButtons: {
            showDisplaySetting: {
              ext: {
                hidden: true,
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            createdTime: {
              text: "Thời gian tác động",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            user: {
              text: "Người dùng",
              sortable: true,
              options: {
                subProp: "user.name",
              },
            },
            actionType: {
              text: "Loại tác động",
              sortable: true,
              options: {
                label: true,
              },
            },
            targetBefore: !self.displayFields && {
              text: "Trạng thái trước",
              options: {
                json: true,
              },
            },
            targetAfter: !self.displayFields && {
              text: "Trạng thái sau",
              options: {
                json: true,
              },
            },
            changedDataRaw: !self.displayFields && {
              text: "Thay đổi",
              options: {
                json: true,
                preTransform(_, item) {
                  const targetBefore = item.targetBefore || {};
                  const targetAfter = item.targetAfter || {};
                  const result: any = getDeepDifference(targetBefore, targetAfter);
                  delete result._id;
                  delete result.createdTime;
                  delete result.updatedTime;
                  delete result.lastUpdatedByUserId;
                  return result;
                },
              },
            },
            changedData: self.displayFields && {
              text: "Thay đổi",
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "targetType",
              operator: "equal_to",
              value: self.targetType,
            });
            options.filters.push({
              key: "targetIds",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("actionLogs", "findAll", options);
              return [items, count];
            },
          },
        },
      };
    },
  },
});
